import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";


const NewcalendarPage1 = React.lazy(() => import('./page/NewcalendarPage1'));
const NewcalendarPage2 = React.lazy(() => import('./page/NewcalendarPage2'));
const NewcalendarPage3 = React.lazy(() => import('./page/NewcalendarPage3'));
const NewcalendarPage4 = React.lazy(() => import('./page/NewcalendarPage4'));
const NewcalendarPageCom = React.lazy(() => import('./page/NewcalendarPageCom'));
const Complate = React.lazy(() => import('./page/complate'));
const information = React.lazy(() => import('./page/information'));
const NotFoundPage = React.lazy(() => import('./page/NotFoundPage'));
const Getting = React.lazy(() => import('./page/Getting'));
const Getting_danger = React.lazy(() => import('./page/Getting_danger'));
const Getting_skin = React.lazy(() => import('./page/Getting_skin'));
const Getting_med = React.lazy(() => import('./page/Getting_med'));
const Getting_tool = React.lazy(() => import('./page/Getting_tool'));
const Getting_food = React.lazy(() => import('./page/Getting_food'));
function App() {
  return (
    <div className="">
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/getting" />)} />
            <Route  path="/getting" component={Getting} />
            <Route  path="/1" component={NewcalendarPage1} />
            <Route  path="/2" component={NewcalendarPage2} />
            <Route  path="/3" component={NewcalendarPage3} />
            <Route  path="/4" component={NewcalendarPage4} />
            <Route  path="/5" component={NewcalendarPageCom} />
            <Route  path="/detail/tool" component={Getting_tool} />
            <Route  path="/detail/food" component={Getting_food} />
            <Route  path="/detail/med" component={Getting_med} />
            <Route  path="/detail/skin" component={Getting_skin} />
            <Route  path="/detail/tool" component={Getting_tool} />
            <Route  path="/detail/danger" component={Getting_danger} />
            <Route  path="/complete" component={Complate} />
            <Route  path="/info" component={information} />
            <Route component={NotFoundPage} />
        </Switch>
    </div>
  );
}

export default App;
